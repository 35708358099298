<template>
    <div class="elem">
    <PreLoader v-if="isLoading"></PreLoader>
        <div class="site-wrap" id="home-section">
            <topHeader/>
            <v-card
                class="elem__cards"
            >
                <v-card-title>
                    <v-row>
                        <v-col md="6" cols="6">
                            <h5><b>Beginner Videos</b></h5>
                        </v-col>
                        <v-col md="6" cols="6" align="end">
                            <v-btn style="color:#fff;" color="#f0bd30" @click="goTo('elementary')">Артка кайтуу</v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-container fluid>
                    <v-row dense>
                        <v-col
                        v-for="card in cards"
                        :key="card.title"
                        md="4" sm="6" xs="12" cols="12"
                        >
                            <v-card @click="playVideo">
                                <!-- <v-img
                                :src="card.src"
                                class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                
                                > </v-img>-->
                                <youtube height="300px" width="100%" :player-vars="playerVars" :video-id="card.src" ref="youtube" @playing="playing"></youtube>
                                <v-card-title><h5>{{card.title}}</h5></v-card-title>
                                

                                <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn icon v-if="card.isFav">
                                    <v-icon color="red" @click="setFav(card)">mdi-heart</v-icon>
                                </v-btn>
                                <v-btn icon v-if="!card.isFav">
                                    <v-icon color="red" @click="setFav(card)">mdi-heart-outline</v-icon>
                                </v-btn>

                                <v-btn icon>
                                    <v-icon color="primary">mdi-bookmark</v-icon>
                                </v-btn>

                                <v-btn icon>
                                    <v-icon color="#ff8b00" @click="sharedialog= true">mdi-share-variant</v-icon>
                                </v-btn>
                                
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            
            <v-dialog
                v-model="sharedialog"
                width="500"
                
                >
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                    Privacy Policy
                    </v-card-title>

                    <v-card-text style="font-size:18px;" class="mt-5">
                        <ShareNetwork
                            v-for="network in networks"
                            :network="network.network"
                            :key="network.network"
                            :style="{backgroundColor: network.color,marginLeft: network.mar,borderRadius:network.pad,padding:network.pad}"
                            :url="sharing.url"
                            :title="sharing.title"
                            :description="sharing.description"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                            :twitterUser="sharing.twitterUser"
                        >
                            
                            <i style="margin: 10px 0px 10px 0px;" :class="network.icon"> {{ network.name }}</i>
                        </ShareNetwork>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="sharedialog = false"
                        >
                            I accept
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Footer/>
        </div>
    </div>
</template>
<script>
import topHeader from '../../views/navigation/topHeader';
import Footer from '../../views/navigation/Footer';
import PreLoader from '../../components/preloader/PreLoader'
export default {
    components: {
      topHeader,
      Footer,
      PreLoader
    },
    data: () => ({
        sharedialog: false,
        isFav: false,
        isLoading:false,
        cards: [
            {   
                title: 'Watch the video and learn the names of some countries and nationalities.', 
                src: 'NJVV1LDt2hE',
                isFav: false,
            },
            { 
                title: 'Listen and repeat the ordinal numbers.', 
                src: 'rBycKGkHVpY' ,
                isFav: false,
            },
            { 
                title: 'Listen to Fred describing his day.', 
                src: 'RP1AL2DU6vQ' ,
                isFav: false,
            },
            { 
                title: 'Listen to the song and learn the names of body parts.', 
                src: 'BwHMMZQGFoM',
                isFav: false,
            },
            { 
                title: 'Listen and repeat the names of the vegetables.', 
                src: 'yTEB4hdrJQU',
                isFav: false,
            },
            { 
                title: 'Watch the video and learn the names of different food.', 
                src: 'rkoKjUayyS8',isFav: false,
            },
            { 
                title: 'Watch the video and learn how to shop at the grocery store.', 
                src: 'aC-1vsQqqfw',isFav: false,
            },
            { 
                title: 'Watch the video and learn parts of the house.', 
                src: 'rtdsciznDYg',isFav: false,
            },
            { 
                title: 'Watch the video and learn cooking verbs.', 
                src: 'oqfh5i5Zfcs',isFav: false,
            },
            { 
                title: 'Watch the video and learn about feelings and emotions.', 
                src: '7uY2HrQ9qQ8',isFav: false,
            },
            { 
                title: 'Watch the video and try to answer the question: “What do you do in your free time?”', 
                src: '5bjHaaRsj30',isFav: false,
            },
            { 
                title: 'Watch the video and try to answer the question: “What is your hobby?”', 
                src: 'MW2dtfFyc3k',isFav: false,
            },
            { 
                title: 'Watch the video and learn how to talk about your friends.', 
                src: '-2nQ9j7D03Y',isFav: false,
            },
            { 
                title: 'Watch the video and learn some phrasal verbs.', 
                src: 'cCh0EDHKTcc',isFav: false,
            },
            { 
                title: 'Watch the video and learn how to order food at a restaurant.', 
                src: 'bgfdqVmVjfk',isFav: false,
            },
            { 
                title: 'Watch the video and learn the names of places in a city.', 
                src: 'hpX8UkieizY',isFav: false,
            },
            { 
                title: 'Watch the video and learn how to talk about your hometown.', 
                src: 'xv7zmrRPHZs',isFav: false,
            },
            { 
                title: 'Listen to “The Town Mouse and the Country Mouse” tale.', 
                src: 'V4hjQalsndE',isFav: false,
            },
            { 
                title: 'Listen to the amazing facts about your body.', 
                src: '27H8jxZJXII',isFav: false,
            },
            { 
                title: 'Watch the video and learn how to say the date.', 
                src: 'kDxn-QeT2ok',isFav: false,
            },
            { 
                title: 'Listen to “The story of Peter Pan”.', 
                src: 'LHFikAh6NSQ',isFav: false,
            },
            { 
                title: 'Listen to the “Beauty and the Beast” tale.', 
                src: 'byKELv7gcDE',isFav: false,
            },
            { 
                title: 'Listen to the “Feeling Good” song by Michael Buble.', 
                src: 'YwVEXGq1WN0',isFav: false,
            }
        ],
        sharing: {
            url: 'https://zhyldyzacademy.kg',
            title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
            description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
            quote: 'The hot reload is so fast it\'s near instant. - Evan You',
            hashtags: 'vuejs,vite,javascript',
            twitterUser: 'youyuxi'
        },
        networks: [
            { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#EA4335', mar:'10px',pad:'6px' },
            { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2',mar:'10px',pad:'6px' },
            { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5',mar:'10px',pad:'6px'},
            { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff',mar:'10px',pad:'6px' },
            { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b',mar:'10px',pad:'6px' },
            { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc',mar:'10px',pad:'6px' },
            { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2',mar:'10px',pad:'6px' },
            { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8',mar:'10px',pad:'6px' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366',mar:'10px',pad:'6px' },
        ],
        playerVars: {
            autoplay: 0
        }
    }),
    mounted() {
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        },500)
    },
    methods: {
        goTo (url) {
            this.link = url;
            switch (url) {
                case 'elementary':
                    this.$router.push({ path: '/elementary' });
                    break;
                case 'videos':
                    this.$router.push({ name: 'elemVideos' });
                    break;
                case 'grammar':
                    this.$router.push({ name: 'elemGrammar' });
                    break;
                case 'reading':
                    this.$router.push({ name: 'elemReading' });
                    break;
            }
        },
        playVideo() {
            this.player.playVideo()
        },
        playing() {
            console.log('we are watching!!!')
        },
        setFav(item) {
            item.isFav = true;
        }
    }
}
</script>
<style lang="scss" scoped>
.elem{
    &__cards {
        
    }
}
.v-application a {
    color: #fff  !important;
    & span {
        margin:10px;
    }
}
</style>